<template>
  <div>
    <b-sidebar
      :visible="sideBarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg sidebar-xl"
      shadow
      backdrop
      no-header
      right
      @change="closeSideBar"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ sideBarTitle }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <invoice-form
          v-if="formKey"
          :form-values="formValues"
          :view-only="false"
          @success="formSuccess"
        />
      </template>
    </b-sidebar>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Consumer Type"
              :options="consumerTypeOptions"
              class="w-100"
              @input="consumerFilterChanged"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center justify-content-end">
              <h5 class="mb-0 pr-1">
                Billing Month
              </h5>
              <month-picker @input="datePicked" />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="$can('download', 'billing') && isExistsDownloadAllBills"
                variant="primary"
                @click="emitPDF()"
              >
                Download All Bills
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDataListTable"
        class="position-relative table-data has-filter-thead"
        :items="fetchDataList"
        responsive
        hover
        striped
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sticky-header="true"
      >
        <!-- Filters -->
        <template #thead-top="data">
          <b-tr class="filter-thead">
            <b-th
              v-for="field in data.fields"
              :key="field.key"
            >
              <b-form-input
                v-if="field.filter"
                v-model="filterInputs[field.key]"
                class="w-full m-0"
                :placeholder="'' + field.label"
              />
            </b-th>
            <!-- <span>{{data.fields}}</span> -->
          </b-tr>
        </template>

        <!-- Column: Billing Month -->
        <template #cell(billing_month)="data">
          {{ resolveBillingMonth(data.item.billing_month) }}
        </template>

        <!-- Column: Amount -->
        <template #cell(amount)="data">
          <div class="text-right">
            {{ data.item.amount }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="inline-flex">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="$can('read', 'billing')"
                @click="downloadPDF(data.item)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="inline"
                />
                <span class="align-middle ml-50">PDF</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$can('update', 'billing')"
                @click="openSideBardFor('update', data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="inline"
                />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$can('delete', 'billing')"
                @click="tryDelete(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="inline"
                />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>

            <a
              title="Download PDF"
              class="p-1"
              @click="downloadPDF(data.item)"
            >
              <feather-icon
                class="cursor-pointer z-10"
                icon="DownloadIcon"
                size="16"
              />
            </a>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="flex flex-col mb-1 mb-md-0"
          >
            <span
              class="mb-1"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
            <div>
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <busy-modal :card="busyCard" />
  </div>
</template>
<script>
import BusyModal from '@leecom/busy-modal/BusyModal.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BTr,
  BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import DatePicker from '@leecom/date-picker/DatePicker.vue'
import MonthPicker from '@leecom/date-picker/MonthPicker.vue'
import InvoiceForm from './InvoiceForm.vue'

export default {
  components: {
    BCard,
    BRow,
    BTr,
    BTh,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,

    vSelect,
    DatePicker,
    BusyModal,
    InvoiceForm,
    MonthPicker,
  },
  props: {
    module: {
      type: Object,
      required: true,
    },
    useList: {
      type: Function,
      required: true,
    },
    useSideBar: {
      type: Function,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  setup(props, { root: $this }) {
    const STORE_MODULE_NAME = props.moduleName
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, props.module)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const formSuccess = value => {
      closeSideBar(!value)
      refetchData()
    }

    const tryDelete = item => {
      console.log(item)
      $this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete invoice (${item.invoice_no})`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            deleteItem(item)
          }
        })
    }

    const {
      fetchDataList,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      filterInputs,

      refetchData,

      resolveRolesLabel,
      resolveUserStatusLabel,
      resolveUserStatusVariant,
      resolveBillingMonth,

      consumerTypeOptions,

      basicFilter,

      consumerFilterChanged,
      datePicked,
      downloadPDF,
      deleteItem,
      emitPDF,

      addBtnText,

      isExistsDownloadAllBills,

      busyCard,
    } = props.useList()

    const {
      sideBarTitle,
      sideBarActive,

      openSideBardFor,
      closeSideBar,

      formKey,
      formValues,
      formViewOnly,
    } = props.useSideBar()

    return {
      fetchDataList,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      filterInputs,

      refetchData,

      resolveRolesLabel,
      resolveUserStatusLabel,
      resolveUserStatusVariant,
      resolveBillingMonth,

      consumerTypeOptions,

      basicFilter,

      consumerFilterChanged,
      datePicked,
      downloadPDF,
      deleteItem,
      emitPDF,

      addBtnText,

      isExistsDownloadAllBills,

      busyCard,

      sideBarTitle,
      sideBarActive,

      openSideBardFor,
      closeSideBar,

      formKey,
      formValues,
      formViewOnly,

      formSuccess,

      tryDelete,
    }
  },
}
</script>
