import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'
import moment from "moment-timezone";

moment.locale("en");
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { showErrorToast } from '@leecom/utils/support'

export default function useDataList() {

  const toast = useToast()
  const busyCard = ref({
    state: null,
  })

  const refDataListTable = ref(null)
  const STORE_MODULE_NAME = 'app-invoices'
  const addBtnText = '';

  const tableColumns = [
    { key: 'actions' },
    { key: 'invoice_no', sortable: true, filter: true },
    { key: 'billing_month', sortable: true, filter: false, },
    { key: 'consumer_name', sortable: true, filter: true, },
    { key: 'amount', sortable: true, filter: false, },
    { key: 'due_date', sortable: true, filter: false, },
  ]

  const perPage = ref(10)
  const totalRows = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref(null)
  const isSortDirDesc = ref(true)
  const basicFilter = ref(null)
  const filterInputs = ref({
    invoice_no: null,
    consumer_name: null,
    consumer_type: null,
  })
  const startTimestamp = ref(moment
    .tz(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00'), 'UTC')
    .valueOf())
  const endTimestamp = ref(moment
    .tz(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD 23:59:59'), 'UTC')
    .valueOf())

  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    }
  })

  const refetchData = () => {
    refDataListTable.value.refresh()
    checkDownloadAllBills();
  }

  const refetchDataWithDebounce = _.debounce(() => {
    refDataListTable.value.refresh()
    checkDownloadAllBills();
  }, 300);

  watch([currentPage, perPage, searchQuery, basicFilter], () => {
    refetchData()
  })

  watch(filterInputs.value, () => {
    refetchDataWithDebounce()
  })

  const consumerTypeOptions = computed(() => (store.state.app.enums
    ? _.cloneDeep(store.state.app.enums.ConsumerType)
    : []))



  const resolveRolesLabel = roles => {
    return roles.map(({ name }) => _.startCase(name)).join(", ")
  }

  const resolveUserStatusLabel = status => {
    const { label } = _.find(store.state.app.enums.UserStatusType, { value: status });
    return label;
  }

  const resolveUserStatusVariant = status => {
    const { key } = _.find(store.state.app.enums.UserStatusType, { value: status });
    if (key === 'PENDING') return 'warning'
    if (key === 'ACTIVE') return 'success'
    if (key === 'DISABLED') return 'secondary'
    return 'primary'
  }

  const resolveBillingMonth = date => {
    return moment(date).format('MM/YYYY');
  }


  const consumerFilterChanged = (filter) => {
    if (!filter) {
      filterInputs.value.consumer_type = null;
      return;
    }
    filterInputs.value.consumer_type = filter.value
  }

  const datePicked = (date) => {
    startTimestamp.value = moment
      .tz(date.format('YYYY-MM-DD 00:00:00'), 'UTC')
      .valueOf()

    endTimestamp.value = moment
      .tz(date.clone()
        .startOf('month')
        .add('month', 1)
        .subtract('day', 1).format('YYYY-MM-DD 23:59:59'), 'UTC')
      .valueOf()

    refetchData()
    checkDownloadAllBills();
  }

  const fetchDataList = (ctx, callback) => {
    store
      .dispatch(`${STORE_MODULE_NAME}/fetchDataList`, {
        queryParams: {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          filter: basicFilter.value,
          startTimestamp: startTimestamp.value,
          endTimestamp: endTimestamp.value,
        },
        body: {
          filters: filterInputs.value,
        }
      })
      .then((response) => {

        const { data, total } = response.data.results;

        callback(data)
        totalRows.value = total
      })
      .catch(error => {
        const { response } = error
        showErrorToast(toast, response)
        console.log(error);
      })
  }

  const downloadPDF = (item) => {
    busyCard.value.state = 'WAITING';

    store.dispatch(`${STORE_MODULE_NAME}/downloadPDF`, {
      invoiceId: item.id
    })
      .then((response) => {
        const fileName = response.headers['file-name'];
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);

        fileLink.click();

        busyCard.value.state = null;
      })
      .catch((error) => {
        console.log(error);
        const { response } = error;
        busyCard.value.state = null;
        showErrorToast(toast, response)
      })

  }

  const deleteItem = (item) => {
    busyCard.value.state = 'WAITING';

    store.dispatch(`${STORE_MODULE_NAME}/deleteInvoice`, {
      id: item.id
    })
      .then((response) => {
        busyCard.value.state = null;
        refetchData()
      })
      .catch((error) => {
        console.log(error);
        const { response } = error;
        busyCard.value.state = null;
        showErrorToast(toast, response)
      })
  }

  const emitPDF = () => {
    busyCard.value.state = 'WAITING';

    store.dispatch(`${STORE_MODULE_NAME}/billsOfMonthPDF`, {
      billingMonth: startTimestamp.value
    })
      .then((response) => {
        const fileName = response.headers['file-name'];
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);

        fileLink.click();

        busyCard.value.state = null;
      })
      .catch((error) => {
        console.log(error);
        const { response } = error;
        busyCard.value.state = null;
        showErrorToast(toast, response)
      })
  }

  const isExistsDownloadAllBills = ref(false);
  const checkDownloadAllBills = () => {
    store.dispatch(`${STORE_MODULE_NAME}/billsOfMonthPDFAvailable`, {
      billingMonth: startTimestamp.value
    })
      .then((response) => {
        console.log(response);
        isExistsDownloadAllBills.value = response;
      })
      .catch((error) => {
        console.log(error);
        const { response } = error;
        showErrorToast(toast, response)
      })
  }

  return {
    fetchDataList,
    tableColumns,
    perPage,
    currentPage,
    totalRows,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDataListTable,
    filterInputs,

    refetchData,

    consumerTypeOptions,

    resolveRolesLabel,
    resolveUserStatusLabel,
    resolveUserStatusVariant,
    resolveBillingMonth,

    basicFilter,

    consumerFilterChanged,
    datePicked,
    downloadPDF,
    deleteItem,
    emitPDF,

    addBtnText,

    isExistsDownloadAllBills,

    busyCard,
  }
}
