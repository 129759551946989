import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDataList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/billing/water-invoice/paginate', payload.body, { params: payload.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchInvoice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/billing/water-invoice/${payload.invoiceId}`)
          .then(({ data: { results } }) => resolve(results))
          .catch(error => reject(error))
      })
    },

    updateInvoice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`/billing/water-invoice/${payload.id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteInvoice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios.delete(`/billing/water-invoice/${payload.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadPDF({ state }, payload) {
      const url = `${axios.defaults.baseURL}//billing/water-invoice/${payload.invoiceId}/pdf`
      axios.defaults.responseType = 'blob'
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then((response) => {
            delete axios.defaults.responseType;
            resolve(response)
          })
          .catch(error => {
            delete axios.defaults.responseType;
            reject(error)
          })
      })
    },

    billsOfMonthPDF({ state }, payload) {
      const url = `${axios.defaults.baseURL}//billing/water-invoice/of-month/${payload.billingMonth}/pdf`
      axios.defaults.responseType = 'blob'
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then((response) => {
            delete axios.defaults.responseType;
            resolve(response)
          })
          .catch(error => {
            delete axios.defaults.responseType;
            reject(error)
          })
      })
    },

    billsOfMonthPDFAvailable(ctx, payload) {
      const url = `${axios.defaults.baseURL}//billing/water-invoice/of-month/${payload.billingMonth}/available`
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(({ data: { results } }) => resolve(results))
          .catch(error => reject(error))
      })
    },
  },
}
