<template>
  <section
    class="p-2"
    :class="{ 'area-busy': waiting }"
  >
    <validation-observer
      ref="validationRules"
      #default="{ invalid }"
    >
      <b-row
        v-if="invoiceData"
        class="invoice-add text-black"
      >
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Consumer Details -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1 font-bold">
                              Customer Name:
                            </td>
                            <td>
                              <span>{{
                                invoiceData.consumer_name
                              }}</span>
                            </td>
                          </tr>

                          <tr>
                            <td class="pr-1 font-bold">
                              CR/CPR No:
                            </td>
                            <td>
                              <span>{{ invoiceData.cpr_no }}</span>
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Well No:
                            </td>
                            <td>
                              <span>{{ invoiceData.well_no }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Block:
                            </td>
                            <td>
                              <span>{{ invoiceData.block }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Road:
                            </td>
                            <td>
                              <span>{{ invoiceData.road }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Building:
                            </td>
                            <td>
                              <span>{{ invoiceData.building }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              PO Box:
                            </td>
                            <td>
                              <span>{{ invoiceData.po_box }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>

                  <!-- Col: Invoice Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  >
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1 font-bold">
                              Invoice #:
                            </td>
                            <td>
                              <span>{{ invoiceData.invoice_no }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Date:
                            </td>
                            <td>
                              <span>{{ invoiceData.date }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Due Date:
                            </td>
                            <td>
                              <span>{{ invoiceData.due_date }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Past Reading:
                            </td>
                            <td>
                              <span>{{ invoiceData.previous_reading }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Current Reading:
                            </td>
                            <td>
                              <span>{{ invoiceData.current_reading }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Meter Reading Date:
                            </td>
                            <td>
                              <span>{{ invoiceData.meter_reading_date }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Consumer Type:
                            </td>
                            <td>
                              <span>{{ invoiceData.consumer_type }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 font-bold">
                              Billing Period:
                            </td>
                            <td>
                              <span>{{ invoiceData.billing_period }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{ height: trHeight }"
                >
                  <b-row
                    v-for="(item, index) in invoiceData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                            class="text-center"
                          >
                            Services & Charges
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                            class="text-center"
                          >
                            {{ item.type == 1 ? 'Consumption' : 'Quantity' }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                            class="text-center"
                          >
                            {{
                              item.type == 1
                                ? 'Price Per Cubic Meter (BHD)'
                                : 'Unit Price (BHD)'
                            }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                            class="text-center"
                          >
                            {{
                              item.type == 1
                                ? 'Amount (BHD)'
                                : 'Line Total (BHD)'
                            }}
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Description"
                              vid="Description"
                              rules="required"
                            >
                              <label class="d-inline d-lg-none">
                                Services & Charges</label>
                              <b-form-input
                                v-model="item.description"
                                type="text"
                                class=""
                                :disabled="item.type == 1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Consumption"
                              vid="Consumption"
                              rules="required"
                            >
                              <label class="d-inline d-lg-none">
                                {{
                                  item.type == 1 ? 'Consumption' : 'Quantity'
                                }}
                              </label>
                              <b-form-input
                                v-model="item.quantity"
                                type="number"
                                class=""
                                :disabled="item.type == 1"
                                @input="updateItemForm(index, item)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Price Per Cubic Meter"
                              vid="Price Per Cubic Meter"
                              rules="required"
                            >
                              <label class="d-inline d-lg-none">
                                {{
                                  item.type == 1
                                    ? 'Price Per Cubic Meter (BHD)'
                                    : 'Unit Price (BHD)'
                                }}
                              </label>
                              <b-form-input
                                v-model="item.price"
                                type="number"
                                class=""
                                :disabled="item.type == 1"
                                @input="updateItemForm(index, item)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">
                              {{
                                item.type == 1
                                  ? 'Amount (BHD)'
                                  : 'Line Total (BHD)'
                              }}
                            </label>
                            <p
                              v-if="item.type == 1"
                              class="py-[0.48rem] mb-0 text-right"
                            >
                              {{ item.total | formatCurrency }}
                            </p>
                            <p
                              v-else
                              class="py-[0.48rem] mb-0 text-right"
                            >
                              {{
                                bcMul(item.quantity, item.price, 3)
                                  | formatCurrency
                              }}
                            </p>
                          </b-col>
                        </b-row>
                        <div
                          v-if="item.type != 1"
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  Add Item
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Sales Persion -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  />

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total (BHD):
                        </p>
                        <p class="invoice-total-amount">
                          {{ invoiceData.amount | formatCurrency }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-bold">Remarks: </span>
                <b-form-textarea v-model="invoiceData.remark" />
              </b-card-body>
            </b-card>
          </b-form>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        :disabled="invalid || waiting"
        class="mr-1"
        block
        @click="submitHandler"
      >
        <span v-if="waiting"><b-spinner small /> Saving...</span>
        <span v-else>Save</span>
      </b-button>
    </validation-observer>
    <busy-modal :card="busyCard" />
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import BusyModal from '@leecom/busy-modal/BusyModal.vue'
import Logo from '@core/layouts/components/Logo.vue'
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { bcMul, showErrorToast } from '@leecom/utils/support'
import { formatCurrency } from '@leecom/utils/filter'
import _ from 'lodash'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    BSpinner,
    flatPickr,
    vSelect,
    Logo,
    BusyModal,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  props: {
    formValues: {
      type: Object,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      // validation
      required,
    }
  },
  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'invoiceData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    bcMul,
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)

      this.invoiceData.amount = _.sumBy(
        this.invoiceData.items,
        item => item.total * 1,
      )
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
  },
  setup(props, { emit }) {
    const formData = ref(props.formValues)
    const vieOnlyProp = ref(props.viewOnly)
    const STORE_MODULE_NAME = 'app-invoices'

    const toast = useToast()

    const busyCard = ref({
      state: null,
    })

    const invoiceData = ref({})
    const paymentDetails = ref({})

    const waiting = ref(false)

    const fetchInvoice = () => new Promise((resolve, reject) => {
      busyCard.value.state = 'WAITING'
      store
        .dispatch(`${STORE_MODULE_NAME}/fetchInvoice`, {
          invoiceId: formData.value.id,
        })
        .then(response => {
          resolve(response)
          invoiceData.value = response
          busyCard.value.state = null
        })
        .catch(error => {
          console.log(error)
          const { response } = error
          busyCard.value.state = null
          showErrorToast(toast, response)
          reject(response)
        })
    })

    fetchInvoice()

    const itemFormBlankItem = {
      type: 2,
      price: 0,
      quantity: 0,
      description: '',
      total: null,
    }

    const updateItemForm = (index, val) => {
      const { price, quantity, description } = val
      invoiceData.value.items[index].price = price
      invoiceData.value.items[index].quantity = quantity
      invoiceData.value.items[index].description = description
      invoiceData.value.items[index].total = bcMul(price, quantity, 3)

      invoiceData.value.amount = _.sumBy(
        invoiceData.value.items,
        item => item.total * 1,
      )
    }

    const submitHandler = () => {
      submitForm()
        .then(() => {
          emit('success', true)
        })
        .catch(() => {})
    }

    const submitForm = () => new Promise((resolve, reject) => {
      waiting.value = true
      store
        .dispatch(
          `${STORE_MODULE_NAME}/${formData.value.mode}`,
          invoiceData.value,
        )
        .then(response => {
          waiting.value = false
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          const { response } = error
          waiting.value = false
          reject(response)
          showErrorToast(toast, response)
        })
    })

    return {
      invoiceData,

      updateItemForm,
      itemFormBlankItem,

      submitHandler,

      waiting,

      busyCard,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
