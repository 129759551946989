import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'
import moment from "moment-timezone";

moment.locale("en");
moment.tz("UTC");

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSideBar() {
    const STORE_MODULE_NAME = 'app-invoices'

    const toast = useToast()
    const sideBarTitle = ref('');
    const sideBarActive = ref(false);
    const waiting = ref(false);

    const openSideBardFor = (type, data = null) => {
        // if (type == 'store') {
        //     sideBarTitle.value = 'Add User';
        //     formValues.value = {
        //         mode: 'addUser',
        //     }
        //     formViewOnly.value = false;
        // }

        if (type == 'update') {
            sideBarTitle.value = 'Edit Invoice';
            formViewOnly.value = false;
            data.mode = 'updateInvoice';
            formValues.value = data;
        }

        sideBarActive.value = true;
        formKey.value = moment().valueOf();

    }

    const closeSideBar = (event) => {
        sideBarActive.value = event;
        if (!event) {
            formKey.value = null;
        }
    }


    const formKey = ref(null)
    const formValues = ref({});
    const formViewOnly = ref(true);


    return {
        sideBarTitle,
        sideBarActive,

        openSideBardFor,
        closeSideBar,

        formKey,
        formValues,
        formViewOnly,

        waiting,
    }
}