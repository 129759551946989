<template>
  <data-list-component
    :module="invoiceStoreModule"
    :use-list="invoicesDataList"
    :use-side-bar="useSideBar"
    module-name="app-invoices"
  />
</template>

<script>
import DataListComponent from './DataListComponent.vue'
import invoicesDataList from './invoicesDataList'
import invoiceStoreModule from './invoiceStoreModule'
import useSideBar from './useSideBar'

export default {
  components: {
    DataListComponent,
  },
  data() {
    return {
      invoicesDataList,
      invoiceStoreModule,
      useSideBar,
    }
  },
}
</script>

<style></style>
